<template>
  <tr>
    <td>{{ item.name }}</td>
    <td>{{ item.assetCount }}</td>
    <td v-if="userDetail.userTypeId == 1">
      <MDBTooltip v-model="tooltipSetting">
        <template #reference>
          <router-link
            :to="{
              name: 'AssetLabelSettings',
              params: { id: item.id },
            }"
          >
            <button
              type="button"
              outline="primary"
              size="sm"
              floating
              class="btn btn-sm btn-outline-primary btn-floating me-2 mb-2"
            >
              <MDBIcon iconStyle="fas" icon="cog" />
            </button>
          </router-link>
        </template>
        <template #tip> View Settings </template>
      </MDBTooltip>
      <MDBTooltip v-model="tooltipModal">
        <template #reference>
          <button
            type="button"
            outline="primary"
            size="sm"
            floating
            class="btn btn-sm btn-outline-primary btn-floating me-2 mb-2"
            @click.stop="viewAssets(item.id, item.name)"
          >
            <MDBIcon iconStyle="fas" icon="users" />
            <MDBBadge
              badge="danger"
              size="xs"
              class="border border-light rounded-circle position-absolute"
              dot
            >
            </MDBBadge>
          </button>
        </template>
        <template #tip> View Assets </template>
      </MDBTooltip>
    </td>
  </tr>
  <!-- modal -->
  <MDBModal
    id="assetModal"
    tabindex="-1"
    labelledby="assetModalLabel"
    v-model="assetModal"
    size="lg"
  >
    <MDBModalHeader class="p-4">
      <MDBModalTitle id="assetModalLabel">
        {{ selectedLabelAssetsName }}`s Assets
      </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody class="p-4 pb-0">
      <MDBTable striped hover class="align-middle">
        <thead class="table-dark">
          <tr>
            <th scope="col">Asset ID</th>
            <th scope="col">Title</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(assets, x) in selectedLabelAssetsList.data" :key="x">
            <td>{{ assets.assetId }}</td>
            <td v-if="assets.title === ''"><i>(No Title Specified)</i></td>
            <td v-else>{{ assets.title }}</td>
          </tr>
        </tbody>
      </MDBTable>
      <Paginate
        :pageType="'assetLabelAssets'"
        :assetLabelId="selectedLabelAssetsId"
        @paginationData="selectedLabelAssetsList = $event"
      >
      </Paginate>
    </MDBModalBody>
    <MDBModalFooter class="py-3">
      <MDBBtn color="primary" size="sm" @click="closeAssetsModal()"> Close </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>
<script setup>
import { ref, defineProps } from "vue";
import {
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBIcon,
  MDBBadge,
  MDBTable,
} from "mdb-vue-ui-kit";
import Paginate from "../../components/Pagination/CSDPagination.vue";
import axios from "axios";
const tooltipSetting = ref(false);
const tooltipModal = ref(false);
const assetModal = ref(false);
defineProps({
  item: Object,
  userDetail: Object,
});

const selectedLabelAssetsList = ref([]);
const selectedLabelAssetsId = ref(null);
const selectedLabelAssetsName = ref("");
const viewAssets = async (id, name) => {
  await axios
    .get(`api/assetlabels/${id}/Assets`)
    .then(function (response) {
      selectedLabelAssetsId.value = id;
      selectedLabelAssetsName.value = name;
      selectedLabelAssetsList.value = response.data;
      assetModal.value = true;
    })
    .catch(function (error) {
      console.log("error", error);
    });
};
const closeAssetsModal = () => {
  selectedLabelAssetsId.value = null;
  selectedLabelAssetsName.value = "";
  selectedLabelAssetsList.value = [];
  assetModal.value = false;
};
</script>
<style scoped>
.btn-primary {
  background-color: var(--primary);
}
.btn-outline-primary {
  border-color: var(--accent);
  color: var(--accent);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
</style>
<style>
.tooltip-inner {
  font-family: "Poppins", sans-serif !important;
  background-color: var(--primary);
  font-size: 12px;
  padding: 3px 12px;
}
.badge-dot {
  background-color: var(--primary);
}
</style>
