<template>
  <LayoutNew>
    <h1 class="text-center">Asset Labels</h1>
    <MDBCard id="AssetLabels" class="mb-2">
      <!-- search bar -->
      <MDBCardHeader class="d-md-flex d-block justify-content-between p-4">
        <div class="d-flex justify-content-start">
          <MDBBtn color="primary" class="form-icon-trailing mb-md-0 mb-3" size="sm" @click="assetLabelModal = true"
            v-if="isAdmin || isSuperAdmin">
            <MDBIcon iconStyle="fas" icon="folder-plus" class="me-0 me-md-2 trailing" />
            Add
          </MDBBtn>
        </div>
        <div class="d-flex justify-content-end">
          <Search :searchType="'assetLabel'" @searchResult="assetData = $event" searchLabel="Search Asset Labels"
            @isLoading="isLoading = $event" />
        </div>
      </MDBCardHeader>
      <!-- table -->
      <MDBCardBody class="pb-0 overflow-auto">
        <MDBTable striped hover class="align-middle">
          <thead class="table-dark">
            <tr>
              <th v-for="(item, i) in tableHeaders" :key="i" scope="col">
                {{ item.header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="isLoading">
              <tr>
                <td colspan="3" class="text-center">
                  <MDBSpinner />
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-if="assetLabelList.data.length === 0">
                <td colspan="3" class="text-center py-3">No data found.</td>
              </tr>
              <template v-else v-for="(item, k) in assetLabelList.data" :key="k">
                <AssetsLabelsTableRowNew :item="item" :userDetail="userDetail">
                </AssetsLabelsTableRowNew>
              </template>
            </template>
          </tbody>
        </MDBTable>
        <Paginate :pageType="'assetLabel'" @paginationData="assetLabelList = $event">
        </Paginate>
        <MDBToast v-model="toastError" id="basic-danger-example" :delay="2000" autohide position="top-right"
          appendToBody stacking width="350px" color="danger" text="white">
          <template #title> </template>
          {{ toastMessage }}
        </MDBToast>
      </MDBCardBody>
    </MDBCard>
    <MDBModal id="assetLabelModal" tabindex="-1" labelledby="assetLabel" v-model="assetLabelModal" class="ps-0">
      <MDBModalHeader>
        <MDBModalTitle id="assetLabel"> Add Asset Label </MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody>
        <MDBInput v-model="assetLabel" placeholder="Asset Label Name" />
      </MDBModalBody>
      <MDBModalFooter class="gap-2 py-3">
        <MDBBtn @click="assetLabelModal = false" size="sm"> Close </MDBBtn>
        <MDBBtn color="primary" size="sm" @click="addAssetLabel" :disabled="assetLabel.length == 0">
          Confirm
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
    <MDBToast v-model="toastSuccess" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
      color="success" text="white">
      <template #title> Success </template>
      {{ assetLabel }} Added successfully!
    </MDBToast>
  </LayoutNew>
</template>

<script setup>
import LayoutNew from "./LayoutNew.vue";
import Search from "../../components/CSDSearch.vue";
import Paginate from "../../components/Pagination/CSDPagination.vue";
import AssetsLabelsTableRowNew from "./AssetsLabelsTableRowNew.vue";
import { computed, onBeforeMount, ref, watch } from "vue";
import axios from "axios";
import { PostAssetLabels } from "../../services/AssetLabels/PostAssetLabels";
import { useUserDetailStore } from "../../store/userDetailStore";
import { storeToRefs } from "pinia";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBTable,
  MDBToast,
  MDBSpinner,
  MDBBtn,
  MDBInput,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter
} from "mdb-vue-ui-kit";
import { useTitle } from "@vueuse/core";

useTitle("Asset Labels | CreatorShield");

const assetData = ref({
  data: [],
  pagination: {},
});

const userDetailStore = useUserDetailStore();
const { userDetail, isAdmin, isSuperAdmin } = storeToRefs(userDetailStore);

let toastError = ref(false);
let toastMessage = "";

const tableHeaders = computed(() => {
  if (userDetail.value.userTypeId == 1) {
    return [
      {
        header: "Asset Label",
      },
      {
        header: "Total # of Assets",
      },
      {
        header: "Actions",
      },
    ];
  } else {
    return [
      {
        header: "Asset Label",
      },
      {
        header: "Total # of Assets",
      },
    ];
  }
});

const assetLabelList = ref({
  data: [],
  pagination: {},
});

watch(assetData, (newAssetLabelList) => {
  assetLabelList.value = newAssetLabelList;
});

const isLoading = ref(false);

onBeforeMount(() => {
  loadAssetLabels()
});

const loadAssetLabels = async () => {
  isLoading.value = true;
  await axios
    .get("api/assetLabels/List")
    .then(function (response) {
      isLoading.value = false;
      assetLabelList.value = response.data;
    })
    .catch(function (error) {
      isLoading.value = false;
      toastError = true;
      toastMessage = "An error occurred during request" + error;
    });
};

const assetLabelModal = ref(false);
const assetLabel = ref("");
const toastSuccess = ref(false);

const addAssetLabel = async () => {
  const response = await PostAssetLabels(assetLabel.value);
  if (response) {
    loadAssetLabels();
    assetLabelModal.value = false;
    toastSuccess.value = true;
    setTimeout(() => {
      assetLabel.value = "";
    }, 1000);
  }
};
</script>

<style scoped>
.table-dark {
  --mdb-table-bg: var(--accent);
  border-color: var(--accent);
}

.btn-primary {
  background-color: var(--primary);
}

.btn-outline-primary {
  border-color: var(--accent);
  color: var(--accent);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
</style>
