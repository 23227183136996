import { HttpClient } from "../HttpClient";
/**
 * * Post Asset Label
 */
export const PostAssetLabels = async (name) => {
  try {
    const { data } = await HttpClient.post("api/assetLabels/", { Name: name });
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
